<template>
  <AktarRisalahForm mode="Ubah" module="Akta dan Risalah RUPS"> </AktarRisalahForm>
</template>

<script>
import AktarRisalahForm from './form';

const AktarRisalahUpdate = {
  name: 'AktarRisalahUpdate',
  components: { AktarRisalahForm },
};

export default AktarRisalahUpdate;
</script>
